.plugin-manager-title {
  position: sticky;
  top: 0;
  background: var(--tech-white);
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-direction: row;
  gap: 0.5rem;
}

@media (max-width: 900px) {
  .plugin-manager-title {
    flex-direction: column;
  }
}

.plugin-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  margin: 2rem;
}

.plugin-error {
  color: red;
  margin-top: auto;
}

.card {
  word-break: break-word;
  /* padding-block: 0.5rem; */
}

.card-body {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.plugin-card {
  border-radius: 5px;
  color: white;
  width: 100%;
  background: var(--gradient-blue);
}

.plugin-card-error {
  color: black;
  width: 100%;
  border: 1px solid red;
  border-radius: 5px;
}

.plugin-manager-root {
  background-color: whitesmoke;
  height: 100%;
}

.toolbar-search {
  outline: none;
  border: none;
}

.dependencies-scroll {
  max-height: 5rem;
  overflow-y: auto;
  width: 100%;
}
