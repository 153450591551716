.form-control {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  text-transform: capitalize;
}

.form-control::placeholder {
  text-transform: capitalize;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem;
}

.form-floating > label {
  pointer-events: none;
  transform-origin: 0 0;
  color: #6c757d;
  text-transform: capitalize;
  border: 1px solid #0000;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label {
  display: none;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  display: none;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.prompt-overlay {
  z-index: 9999;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.prompt-container {
  text-align: center;
  border-radius: 5px;
  min-width: 150px;
  padding: 5px;
  overflow: auto;
}

.prompt-container p {
  margin-bottom: 10px;
}

.prompt-container input {
  width: 80%;
  margin-bottom: 10px;
}

.loader {
  --size: 50px;
  width: var(--size);
  height: var(--size);
  background-color: var(--vintage-code);
  border-radius: 100%;
  outline: 1px solid #0000;
  animation: 1s ease-in-out infinite pulseanim;
}

@keyframes pulseanim {
  0% {
    opacity: .8;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.plugin-manager-title {
  background: var(--tech-white);
  border-bottom: 1px solid #ccc;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  padding: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (width <= 900px) {
  .plugin-manager-title {
    flex-direction: column;
  }
}

.plugin-list {
  align-items: top;
  flex-flow: column wrap;
  justify-content: center;
  margin: 2rem;
  display: flex;
}

.plugin-error {
  color: red;
  margin-top: auto;
}

.card {
  word-break: break-word;
}

.card-body {
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.plugin-card {
  color: #fff;
  background: var(--gradient-blue);
  border-radius: 5px;
  width: 100%;
}

.plugin-card-error {
  color: #000;
  border: 1px solid red;
  border-radius: 5px;
  width: 100%;
}

.plugin-manager-root {
  background-color: #f5f5f5;
  height: 100%;
}

.toolbar-search {
  border: none;
  outline: none;
}

.dependencies-scroll {
  width: 100%;
  max-height: 5rem;
  overflow-y: auto;
}

.flow-title {
  color: var(--digital-grey-IV);
  height: 3rem;
  font-size: 2rem;
  font-weight: bold;
}

.block-name {
  font-weight: 600;
}

.plugin-block {
  background: var(--grey-white);
  border: 1px solid var(--pop-code);
  border-radius: 10px;
  height: 100%;
  padding: 2px 5px 2px 10px;
  transition: transform .2s ease-in-out;
  position: relative;
  box-shadow: 2px 3px 10px -7px #000;
}

.plugin-block-failed {
  border: 1px solid var(--red-error);
}

.remote-block {
  background-color: var(--light-blue);
}

.subblock {
  margin-left: auto;
  width: calc(100% - 2rem) !important;
}

.subblock-placeholder {
  color: #5d5d5d;
  background-color: #f5f5f5;
  height: 100%;
  margin-left: auto;
}

.block-placeholder {
  color: #dcdcdc;
  background-color: #fff;
}

.plugin-block-placed {
  padding: 10px;
  width: 350px !important;
}

.plugin-block-onair {
  width: 10rem;
}

.plugin-name-block {
  color: #999;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: .8rem;
  position: relative;
}

.block-list-plugin {
  background: var(--gradient-blue);
  border: none;
}

.plugin-variable {
  background: var(--grey-white);
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: .5rem;
  width: 100%;
  padding: .5rem;
  font-size: .8rem;
  display: flex;
}

.plugin-variable-name {
  word-break: break-word;
  color: var(--digital-grey-IV);
  font-size: 1rem;
  font-weight: bold;
}

.plugin-variable-value {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  font-size: 1rem;
  overflow: hidden;
  width: 100% !important;
}

.plugin-variable-value select, .plugin-variable-value input, .plugin-variable-value textarea {
  border-bottom: 1px solid var(--soft-grey);
  width: 100%;
}

.plugin-variable-value:-ms-input-placeholder {
  text-align: center;
  align-items: center;
  padding-top: .5rem;
}

.plugin-variable-value:-moz-placeholder {
  text-align: center;
  align-items: center;
  padding-top: .5rem;
}

.plugin-variable-value::-webkit-input-placeholder {
  text-align: center;
  align-items: center;
  padding-top: .5rem;
}

.plugin-variable-value::placeholder {
  text-align: center;
  align-items: center;
  padding-top: .5rem;
}

.plugin-variable-value.no_border {
  border: none;
}

.plugin-variable-value button {
  text-align: center;
}

.underlined-variable {
  border-bottom: 1px solid var(--pop-code);
  background: var(--grey-white);
  text-align: center;
  width: 100%;
}

.plugin-variable-description {
  width: 100%;
  color: var(--digital-grey-III);
  font-size: 1rem;
}

.plugin-variable-id {
  text-align: center;
  color: gray;
  width: 100%;
  font-size: .8rem;
}

.block-search {
  z-index: 10;
  background: #fff;
  padding-top: 1rem;
  position: fixed;
}

.block-sidebar {
  z-index: 1;
  background: #fff;
  height: 100%;
}

.current-flow {
  background-color: var(--grey-white);
  z-index: 0;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
}

.placed-id {
  color: #fff;
  text-align: center;
  padding: 2px;
}

.top-bar-flow-reciver {
  z-index: 10;
  width: 100%;
}

.flow-canvas {
  background: var(--broken-white);
  place-items: center;
  height: 100%;
  display: grid;
  overflow: hidden;
}

.scaled-flow-canvas {
  transform-origin: center;
  transition: transform .1s ease-in-out;
}

.current-flow input, .current-flow select {
  width: 100%;
}

.block-separator {
  border-bottom: 1px solid #e5e5e5;
}

.flow-name {
  border: 1px solid var(--digital-grey-IV);
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 2px 2px 10px -7px #000;
}

.flow-button {
  width: 3rem;
  height: 3rem;
}

.arrow-right {
  border-top: 1rem solid #0000;
  border-bottom: 1rem solid #0000;
  border-left: 2rem solid var(--tech-blue);
  width: 0;
  height: 0;
}

.connect-blocks {
  z-index: -1;
  border-radius: 25%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 10%;
  left: 98%;
}

.connecting-blocks {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -100%);
}

.remotes {
  opacity: 0;
  text-align: center;
  z-index: -20;
  background-color: #f5f5f5;
  border: 1px solid #000;
  border-radius: 5px;
  align-items: center;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  top: 5rem;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 4px 4px 8px #0000002e;
}

.remotes.active {
  z-index: 10;
  opacity: 1;
  cursor: pointer;
  transform: translateY(0%);
}

.hover-description {
  background-color: var(--broken-white);
  text-align: center;
  z-index: 99999;
  border: 1px solid #000;
  border-radius: 5px;
  min-width: 10rem;
  max-width: 30rem;
  max-height: 30rem;
  position: absolute;
  left: -5rem;
  box-shadow: 4px 4px 8px #0000002e;
}

.blocks-loading {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: -5rem;
  display: flex;
}

*, :before, :after {
  box-sizing: border-box;
}

.bookmark {
  border-top: 10px solid var(--tech-blue);
  border-bottom: 10px solid var(--tech-blue);
  z-index: -1;
  border-left: 10px solid #0000;
  width: 70px;
  height: 10px;
  padding: 0;
  position: absolute;
  top: 2.5rem;
  left: -1rem;
  transform: rotate(0)skew(0deg);
}

.variable-ball {
  z-index: 2;
  cursor: default;
  border: 1px solid #000;
  border-radius: 50%;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  top: 50%;
  left: 100%;
}

.variable-ball-connected {
  background-color: var(--pop-code);
  border: none;
}

.variable-ball-accept {
  background-color: var(--pop-code);
  border: none;
  animation: 1s infinite pulse;
}

.variable-ball-reject {
  background-color: var(--red-error);
  cursor: not-allowed;
  border: none;
}

.variable-ball-required {
  background-color: var(--waring-orange);
  border: none;
}

.variable-ball-flashing {
  cursor: grabbing;
  animation: .5s infinite pulse;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.variable-squared {
  background-color: var(--grey-white);
  z-index: 99;
  border: 1px solid var(--pop-code);
  border-radius: 15px;
  padding-left: .5rem;
  padding-right: .5rem;
  box-shadow: 4px 4px 8px #0000002e;
}

.cut-text-nohover, .cut-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cut-text:hover {
  white-space: normal;
  text-overflow: unset;
  overflow: unset;
  word-break: break-all;
}

.output-squared {
  background-color: var(--broken-white);
  text-align: center;
  z-index: 99;
  border: 1px solid #000;
  border-radius: 5px;
  min-width: 10rem;
  box-shadow: 4px 4px 8px #0000002e;
}

.output-squared:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: .5rem;
}

.output-squared:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .5rem;
}

.output-squared:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .5rem;
}

.remote-block-cloud {
  flex-direction: row;
  gap: .5rem;
  margin-top: .5rem;
  display: flex;
}

.variables-modal-title-search {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

@media (width <= 1200px) {
  .variables-modal-title-search {
    text-align: center;
    flex-direction: column;
  }
}

.variables-modal {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  width: max-content;
  height: max-content;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes expand {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes contract {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.plugin-variable-safari {
  margin-left: 50%;
}

.cycles-box {
  background-color: var(--broken-white);
  text-align: center;
  z-index: 99;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 4px 4px 8px #0000002e;
}

.extensions-box {
  background-color: var(--grey-white);
  text-align: center;
  z-index: 99;
  border: 1px solid var(--pop-code);
  border-radius: 15px;
  position: absolute;
  box-shadow: 4px 4px 8px #0000002e;
}
/*# sourceMappingURL=login.89976bf4.css.map */
