.flow-title {
  color: var(--digital-grey-IV);
  font-size: 2rem;
  font-weight: bold;
  height: 3rem;
}

.block-name {
  font-weight: 600;
}

.plugin-block {
  background: var(--grey-white);
  border-radius: 10px;
  box-shadow: black 2px 3px 10px -7px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  height: 100%;
  padding: 2px 5px 2px 10px;
  border: 1px solid var(--pop-code);
}

.plugin-block-failed {
  border: 1px solid var(--red-error);
}

.remote-block {
  background-color: var(--light-blue);
}

.subblock {
  width: calc(100% - 2rem) !important;
  margin-left: auto;
}

.subblock-placeholder {
  height: 100%;
  margin-left: auto;
  background-color: #f5f5f5;
  color: rgb(93, 93, 93);
}

.block-placeholder {
  background-color: #ffffff;
  color: gainsboro;
}

.plugin-block-placed {
  width: 350px !important;
  padding: 10px;
}

.plugin-block-onair {
  width: 10rem;
}

.plugin-name-block {
  font-size: 0.8rem;
  color: #999;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  position: relative;
}

.block-list-plugin {
  background: var(--gradient-blue);
  border: none;
}

.plugin-variable {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-width: 300px; */
  width: 100%;
  background: var(--grey-white);
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 0.8rem;
  gap: 0.5rem;
}

.plugin-variable-name {
  word-break: break-word;
  font-weight: bold;
  font-size: 1rem;
  color: var(--digital-grey-IV);
}
.plugin-variable-value {
  width: 100% !important;
  overflow: hidden;
  font-size: 1rem;
  background: transparent;
  color: black;
  user-select: none;
}
.plugin-variable-value select,
.plugin-variable-value input,
.plugin-variable-value textarea {
  width: 100%;
  /* text-align: center; */
  border-bottom: 1px solid var(--soft-grey);
}

.plugin-variable-value::-webkit-input-placeholder,
.plugin-variable-value::-moz-placeholder,
.plugin-variable-value:-ms-input-placeholder,
.plugin-variable-value:-moz-placeholder,
.plugin-variable-value::placeholder {
  text-align: center;
  align-items: center;
  padding-top: 0.5rem;
}

.plugin-variable-value.no_border {
  border: none;
}

/*  */
.plugin-variable-value button {
  text-align: center;
}

.underlined-variable {
  width: 100%;
  border-bottom: 1px solid var(--pop-code);
  background: var(--grey-white);
  text-align: center;
}

.plugin-variable-description {
  width: 100%;
  font-size: 1rem;
  color: var(--digital-grey-III);
}

.plugin-variable-id {
  width: 100%;
  text-align: center;
  color: gray;
  font-size: 0.8rem;
}

.block-search {
  position: fixed;
  z-index: 10;
  background: white;
  padding-top: 1rem;
}

.block-sidebar {
  /* Add a right border */
  z-index: 1;
  /* padding-top: 1rem;
  padding-inline: 1rem; */
  /* overflow-y: auto; */
  height: 100%;
  background: white;
}

.current-flow {
  background-color: var(--grey-white);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.placed-id {
  color: white;
  padding: 2px;
  text-align: center;
}

.top-bar-flow-reciver {
  z-index: 10;
  width: 100%;
}

.flow-canvas {
  height: 100%;
  display: grid;
  place-items: center;
  background: var(--broken-white);
  overflow: hidden;
  /* position: relative; */
}

.scaled-flow-canvas {
  transform-origin: center;
  transition: transform 0.1s ease-in-out;
}

/* Set all the inputs to 100% width */
.current-flow input {
  width: 100%;
}

/* Set the dropdowns to 100% width */
.current-flow select {
  width: 100%;
}

.block-separator {
  border-bottom: 1px solid #e5e5e5;
}

.flow-name {
  border: 1px solid var(--digital-grey-IV);
  border-radius: 20px;
  padding-inline: 1rem;
  box-shadow: black 2px 2px 10px -7px;
}

.flow-button {
  height: 3rem;
  width: 3rem;
}

.arrow-right {
  border-top: 1rem solid #0000;
  border-bottom: 1rem solid #0000;
  border-left: 2rem solid var(--tech-blue);
  width: 0;
  height: 0;
}

.connect-blocks {
  border-radius: 25%;
  position: absolute;
  top: 10%;
  left: 98%;
  z-index: -1;
  height: 2rem;
  width: 2rem;
}

.connecting-blocks {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -100%);
  z-index: 2;
}

.remotes {
  position: absolute;
  right: 0;
  top: 5rem;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  transform: translateY(-100%);
  background-color: whitesmoke;
  border-radius: 5px;
  border: 1px solid black;
  align-items: center;
  text-align: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.18);
  z-index: -20;
}

.remotes.active {
  z-index: 10;
  opacity: 1;
  cursor: pointer;
  transform: translateY(0%);
}

.hover-description {
  background-color: var(--broken-white);
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.18);
  min-width: 10rem;
  max-width: 30rem;
  max-height: 30rem;
  text-align: center;
  z-index: 99999;
  border: 1px solid black;
  position: absolute;
  left: -5rem;
}

.blocks-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -5rem;
  text-align: center;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.bookmark {
  border-top: 10px solid var(--tech-blue);
  border-bottom: 10px solid var(--tech-blue);
  border-left: 10px solid #0000;
  width: 70px;
  height: 10px;
  padding: 0;
  position: absolute;
  transform: rotate(0) skew(0deg);
  top: 2.5rem;
  left: -1rem;
  z-index: -1;
}

.variable-ball {
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid black;
  top: 50%;
  left: 100%;
  z-index: 2;
  cursor: default;
}

.variable-ball-connected {
  border: none;
  background-color: var(--pop-code);
}

.variable-ball-accept {
  border: none;
  background-color: var(--pop-code);
  animation: pulse 1s infinite;
}

.variable-ball-reject {
  background-color: var(--red-error);
  /* animation: pulse 1s infinite; */
  cursor: not-allowed;
  border: none;
}

.variable-ball-required {
  background-color: var(--waring-orange);
  border: none;
}

.variable-ball-flashing {
  animation: pulse 0.5s infinite;
  cursor: grabbing;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.variable-squared {
  background-color: var(--grey-white);
  z-index: 99;
  border: 1px solid var(--pop-code);
  border-radius: 15px;
  box-shadow: 4px 4px 8px #0000002e;
  padding-inline: 0.5rem;
}

.cut-text-nohover {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cut-text:hover {
  white-space: normal;
  text-overflow: unset;
  overflow: unset;
  word-break: break-all;
}

.output-squared {
  background-color: var(--broken-white);
  text-align: center;
  z-index: 99;
  border: 1px solid #000;
  border-radius: 5px;
  min-width: 10rem;
  /* position: absolute; */
  box-shadow: 4px 4px 8px #0000002e;
  padding-inline-start: 0.5rem;
}

.remote-block-cloud {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.variables-modal-title-search {
  @apply flex flex-row flex-wrap w-full justify-between items-center px-2;
}

@media (max-width: 1200px) {
  .variables-modal-title-search {
    @apply flex-col text-center;
  }
}

.variables-modal {
  @apply bg-blue-400 w-max h-max fixed;
  top: 0;
  left: 0;
}

@keyframes expand {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes contract {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.plugin-variable-safari {
  margin-left: 50%;
}

.cycles-box {
  background-color: var(--broken-white);
  text-align: center;
  z-index: 99;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 4px 4px 8px #0000002e;
}

.extensions-box {
  background-color: var(--grey-white);
  text-align: center;
  z-index: 99;
  border: 1px solid var(--pop-code);
  border-radius: 15px;
  box-shadow: 4px 4px 8px #0000002e;
  position: absolute;
}
