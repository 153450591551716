.loader {
  --size: 50px;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  background-color: var(--vintage-code);
  outline: 1px solid transparent;
  animation: pulseanim 1s ease-in-out infinite;
}

@keyframes pulseanim {
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
